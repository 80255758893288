.projectInfo {
    background-color: var(--project-bg);
    border: var(--project-bg) solid 2px;
    border-radius: 8px;
    margin: 1em;
    padding: 1em;

    color: var(--text-color);

    /*
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
     */
    display: block;
}

.project-name {
    color: var(--emph-text-color);
    padding: 2px;
    font-size: 17pt;
    font-weight: bold;
}

.project-description {
    width: fit-content;
}

.project-link {
    width: fit-content;
    height: fit-content;
    border-radius: 4px;
    padding: 2px;
}

.project-link a {
    color: inherit;
}

.project-lang {
    display: flex;
}

.project-lang div {
    padding-right: 1em;
    color: var(--secondary-text);
}

.top {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .project-description {
        width: 100%;
    }
}