.experience {
    color: #C5C6C7;
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
    /*padding-bottom: 2em;*/

    padding: 0.5em;
}

.experience:hover {
    background-color: #1a1c25;
    border-radius: 8px;
}

.experience-duration {
    color: var(--secondary-text);
    width: 14em;
    flex-wrap: nowrap;
}

.experience-title {
    font-size: 17px;
    font-weight: bold;
}

.experience-company {
    color: var(--secondary-text);
}

.experience-title {
    color: var(--text-color);
}

.experience-techs {
    color: var(--emph-text-color);
    display: flex;
    flex: auto;
    flex-direction: row;
    padding-right: 2em;
}

.experience-techs div {
    padding-right: 1em;
}

.experience-description {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    padding-left: 2em;
}