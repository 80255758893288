.App {
  background-color: var(--background);
}

.main-content {
  padding: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-bar {
  overflow-x: hidden;
  height: 100%;

  position: fixed;
  width: 25em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  margin-left: 25em;
  padding: 0 10px;
}

footer {
  color: var(--text-color);
  text-align: center;
}

/* For screens smaller than 768px (e.g., mobile phones) */
@media screen and (max-width: 768px) {
  .main-content {
    padding: 1em; /* Reduce padding for small screens */
    display: flex;
    flex-direction: column;
  }

  .side-bar {
    order: -1; /* Move the sidebar to the beginning of the stacking order */
    margin-bottom: 20px; /* Add some spacing between sidebar and main section */
    position: static; /* Remove fixed positioning for small screens */
    height: auto; /* Allow sidebar to adjust its height based on content */
    max-width: 100%; /* Take full width on small screens */
    width: auto;
  }

  .main {
    order: 1; /* Move the main section after the sidebar in the stacking order */
    max-width: 100%; /* Take full width on small screens */
    width: auto;
    margin: 0;
  }
}