.about {
    color: var(--text-color);
}

.about-label {
    font-size: 30px;
}

.about-text {
    padding: 0.5em;
}