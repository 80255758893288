.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.project-label {
    color: var(--text-color);
    font-size: 30px;
}

@media screen and (max-width: 768px) {
    .projects {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
}