.Me {
    padding-left: 2em;
}

.my-name {
    color: var(--text-color);
    font-size: 40px;
    font-weight: bold;
}

.my-job {
    color: var(--text-color);
}